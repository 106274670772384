<template>
  <div>
    <b-sidebar id="sidebar-Fee" bg-variant="white" v-model="visibility" sidebar-class="sidebar-lg" right no-header
      backdrop shadow>
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Settings
        </h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="visibility = false" />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col cols="12" md="12">
            <b-form-group ref="pic" label="Profile" invalid-feedback="Profile is required.">
              <div v-if="profileLoading == true">
                <b-spinner variant="primary" type="grow" class="m-5" label="Spinning"></b-spinner>
              </div>
              <b-img ref="previewEl" src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                @click="$refs.userProfile.click()" v-if="profileLoading == false" style="" height="140px" width="150px" />
              <div v-if="profileLoading == 'loaded'">
                <b-img :src="userObj.pic" height="120px" width="120px" />
                <b-button @click="deletePic()" v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="btn-icon"
                  variant="danger">
                  <feather-icon icon="Trash2Icon" />
                </b-button>

                <br />
              </div>
              <input type="file" id="userProfile" hidden ref="userProfile" @change="selectPic()" accept="image/*" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Title" invalid-feedback="Title is required." ref="user_title">
              <b-form-input placeholder="Enter title" ref="user_title" v-model="userObj.title" @focusout="CheckName()" />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="success" @click="saveUser()"
              :disabled="saving" block>
              <b-spinner v-if="saving" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>
    <b-modal id="modal-login" centered ok-only no-close-on-backdrop hide-footer="true" title="Broadcast">
      <b-form>
        <b-form-group label="Recipient" invalid-feedback="Recipient is required." ref="sendTo">
          <v-select v-model="myObj.recipient" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" ref="sendTo"
            :clearable="false" placeholder="Select recipient" :options="sendToOptions" :reduce="(x) => x.value"
            label="text" @focusout="checkSend()" />
          <!-- @input="setOptions()" -->
        </b-form-group>
        <b-form-group label="Title" invalid-feedback="Title is required." ref="title">
          <b-form-input placeholder="Enter message title" ref="title" v-model="myObj.title" @focusout="CheckTitle()" />
        </b-form-group>
        <b-form-group label="Message" invalid-feedback="Message is required" ref="message">
          <b-form-textarea ref="message" v-model="myObj.message" placeholder="Enter message" rows="4"
            @focusout="CheckMsg()"></b-form-textarea>
        </b-form-group>
      </b-form>
      <br />
      <div style="">
        <b-button class="float-right ml-5" variant="primary" @click="AddFlavour()" :disabled="request">
          <b-spinner v-if="request" variant="light" type="grow" small label="Spinning"></b-spinner>
          <span v-if="!request" class="text-nowrap">Send</span>
        </b-button>
      </div>
    </b-modal>

    <!-- <b-form-group label="Search Business Type" invalid-feedback="Search field">
      <b-form-input
        id="name-input"
        ref="name"
        placeholder="Enter business name to search"
        required
        @input="SearchData()"
        v-model="searchQuery"
      />
    </b-form-group> -->

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Add Flavour -->
          <b-col class="mt-2" md="12">
            <div class="d-flex align-items-center justify-content-between">
              <div style="padding-right: 15px">
                <b-button @click="OpenAddModal()" variant="primary">
                  <!-- <feather-icon icon="PlusIcon" class="mr-50" /> -->
                  <span class="text-nowrap">Broadcast Message</span>
                </b-button>
              </div>
              <div style="">
                <b-button @click="openSettings()" variant="primary">
                  <!-- <feather-icon icon="PlusIcon" class="mr-50" /> -->
                  <span class="text-nowrap">Manage Settings</span>
                </b-button>
              </div>
            </div>
          </b-col>
          <!-- <b-col md="2" cols="4">
            <label for="">Filter</label>
            <v-select
              placeholder="Select status."
              v-model="filterStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              @input="FilterTypes()"
              :clearable="false"
            />
          </b-col> -->
        </b-row>
      </div>

      <div v-if="false">
        <b-table :items="items" :fields="fields" striped>
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(profilePic)="data">
            <b-img :src="data.value" style="width: 100px; height: 100px; object-fit: cover" rounded alt="Rounded image" />
          </template>

          <template #cell(media)="data">
            <b-img :src="data.value" style="width: 100px; height: 100px; object-fit: cover" rounded alt="Rounded image" />
          </template>

          <template #cell(actions)="data">
            <template>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon"
                v-b-tooltip.hover.v-primary v-b-tooltip.placement.left title="Edit" @click="OpenAddModal(data.item.id)">
                <feather-icon size="16" icon="EditIcon" />
              </b-button>

              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-danger" class="ml-1 btn-icon"
                v-b-tooltip.hover.v-danger v-b-tooltip.placement.right title="Delete" size="16"
                @click="DeleteFlavour(data.item.id)">
                <feather-icon size="16" icon="XIcon" />
              </b-button>
            </template>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalOrders" :per-page="perPage" first-number last-number
                class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BSpinner,
  BEmbed,
  BFormCheckbox,
  BSidebar,
  BForm,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    //Multiselect,
    BFormTextarea,
    BSidebar,
    BForm,
    BEmbed,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BMediaAside,
    BSpinner,
    BImg,
    BMedia,
    BFormGroup,
    //BAvatar,
    BLink,
    //BBadge,
    //BDropdown,
    //BDropdownItem,
    BPagination,
    vSelect,
    BFormCheckbox,
    flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  created() {
    this.LoadUser();
  },
  data() {
    return {
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJPc2FtYSIsImVtYWlsIjoib3NhbWF1c21hbjU1NUBnbWFpbC5jb20iLCJqdGkiOiJjMzI0MGI0ZS1mM2NlLTQxZGUtYmRmNS1iYTE1YTFjMjkyZDIiLCJleHAiOjE2NzA1NjU2MjQsImlzcyI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20iLCJhdWQiOiJhcHBpY2tzb2x1dGlvbnMuaW8uY29tIn0.0_pK3DJcXTTEAk-fFziiMOkgGUC7nUYXppJ9C7dOIic",
      plus: "",
      fileProfile: "",
      logoloading: false,
      cover: "",
      coverProfile: "",
      coverLoading: false,
      moreloading: false,
      morefile: null,
      errors: {
        status: false,
      },
      statusOptions: ["active", "inactive"],
      index: null,
      fields: ["#", { label: "message", key: "message" }, "actions"],
      sendToOptions: [
        { text: "All", value: "all" },
        { text: "Talents", value: "talents" },
        { text: "Scouts", value: "scouts" },
      ],
      sendTo: [],
      filterStatus: "",
      items: [],
      request: false,
      myObj: {
        recipient: "",
        title: "",
        message: "",
      },
      rangeDate: "",

      searchQuery: "",
      isUpdating: false,
      moreImg: [],
      visibility: false,
      profileLoading: false,
      saving: false,
      userObj: {},
    };
  },
  methods: {
    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    },
    isVideo(url) {
      return /\.(mp4)$/.test(url);
    },
    SearchData() {
      if (this.searchQuery.length > 0) {
        var data = this.items.filter(
          (data) =>
            JSON.stringify(data)
              .toLowerCase()
              .indexOf(this.searchQuery.toLowerCase()) !== -1
        );
        this.items = data;
      } else {
        this.LoadData();
      }
    },

    CheckUrl() {
      var elem = this.$refs["url"];
      if (this.myObj.url.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    setDate() {
      console.log(this.rangeDate);
      let data = this.rangeDate.split(" to ");
      this.myObj.dtFrom = data[0];
      this.myObj.dtTo = data[1];
      // console.log(this.myObj.date);
      this.CheckDate();
    },
    CheckDate() {
      var elem = this.$refs["date"];
      if (this.myObj.dtFrom == "" || this.myObj.dtTo == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTime() {
      var elem = this.$refs["time"];
      if (this.myObj.time == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    setOptions() {
      if (this.sendTo.at(-1) == "all") {
        this.sendTo = this.sendTo.filter((el) => el == "all");
      } else {
        this.sendTo = this.sendTo.filter((el) => el !== "all");
      }
    },
    CheckMsg() {
      var elem = this.$refs["message"];
      if (this.myObj.message.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSend() {
      var elem = this.$refs["sendTo"];
      if (this.myObj.recipient == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTitle() {
      var elem = this.$refs["title"];
      if (this.myObj.title.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckImage() {
      console.log(this.myObj.media, this.plus);
      var elem = this.$refs["image"];
      if (this.myObj.media == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckStatus() {
      if (this.isUpdating) {
        var elem = this.$refs["status"];
        if (this.myObj.status == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    openSettings() {
      this.visibility = true;
      if (
        this.userObj.pic === null ||
        this.userObj.pic === "" ||
        this.userObj.pic === "-"
      ) {
        this.profileLoading = false;
      } else {
        this.profileLoading = "loaded";
      }
    },
    OpenAddModal() {
      this.myObj = {
        recipient: "",
        title: "",
        message: "",
      };
      this.$bvModal.show("modal-login");
    },
    DeleteFlavour(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require("axios");
          var config = {
            method: "delete",
            url: "https://api.geotalent.co/api/broadcasts/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === "success") {
                Swal.fire(
                  "Success!",
                  "broadcast has been deleted.",
                  "success"
                ).then((res) => {
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    moreImages() {
      this.morefile = this.$refs.morefile.files;
      console.log(this.morefile);
      if (this.morefile.length !== 0) {
        this.moreloading = true;
        let formData = new FormData();
        this.morefile.forEach((el) => {
          formData.append("file", el);
        });
        var axios = require("axios");
        axios
          .post("https://Geoupload.appick.io", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            console.log(e.data);
            e.data.myresp.forEach((el) => {
              this.moreImg.push(el.path);
              this.myObj.moreImages = this.moreImg.join(",");
            });
            this.moreloading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    selectlogo() {
      // "https://geofiles.appick.io/"
      this.fileProfile = this.$refs.fileProfile.files[0];
      console.log(this.fileProfile);
      var axios = require("axios");
      if (this.fileProfile !== "") {
        this.logoloading = true;
        let formData = new FormData();
        formData.append("file", this.fileProfile);
        axios
          .post("https://Geoupload.appick.io", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            console.log(fn1);
            this.plus = fn1;
            this.myObj.media = this.plus;
            // console.log(this.plus);
            this.logoloading = "loaded";
            this.CheckImage();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    selectPic() {
      let pic = this.$refs.userProfile.files[0];

      var axios = require("axios");
      if (pic !== "") {
        this.profileLoading = true;
        let formData = new FormData();
        formData.append("file", pic);
        axios
          .post("https://Geoupload.appick.io", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            // console.log(fn1);
            this.cover = fn1;
            this.userObj.pic = this.cover;
            this.profileLoading = "loaded";
            this.CheckCover();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    deletePic() {
      this.userObj.pic = "";
      this.profileLoading = false;
      this.$refs.userProfile.value = null;
    },
    CheckCover() {
      // console.log(this.userObj.pic);
      var elem = this.$refs["pic"];
      if (this.userObj.pic == "" || this.userObj.pic == "-") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckName() {
      var elem = this.$refs["user_title"];
      if (this.userObj.title.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.profilePic = "";
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    deletemore(ind) {
      this.moreImg.splice(ind, 1);
      this.myObj.moreImages = this.moreImg.join(",");
      // console.log(this.myObj.moreImages.split(","));
    },

    AddFlavour() {
      this.CheckMsg();
      this.CheckSend();
      this.CheckTitle();

      if (
        this.CheckMsg() == false ||
        this.CheckSend() == false ||
        this.CheckTitle() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-bottom-center",
        });
      } else {
        console.log("Obj", this.myObj);
        this.request = true;
        var axios = require("axios");
        var config = {
          method: "post",
          url: "https://api.geotalent.co/api/Users/Broadcast",
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
          data: this.myObj,
        };

        axios(config)
          .then((response) => {
            console.log(response);
            // this.request = false;
            // if (response.data.status === "success") {
            //   console.log(response.data);
            //   Swal.fire(
            //     "Success!",
            //     "Message broadcast successfully!",
            //     "success"
            //   ).then((res) => {
            //     this.$bvModal.hide("modal-login");
            //   });
            // }
          })
          .catch(function(error) {
            this.request = false;
            console.log(error);
          });

        this.request = false;
        Swal.fire("Success!", "Done!", "success").then((res) => {
          this.$bvModal.hide("modal-login");
        });
      }
    },
    LoadData() {
      // console.log("userId",this.$store.state.userData)
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.geotalent.co/api/broadcasts",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response);
          this.items = response.data.data;
          this.items.forEach((el) => {
            el.dtFrom = el.dtFrom.split("T")[0];
            el.dtTo = el.dtTo.split("T")[0];
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    LoadUser() {
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.geotalent.co/api/UserProfile/262",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response.data);
          this.userObj = response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    saveUser() {
      this.CheckCover();
      this.CheckName();
      if (this.CheckCover() == true && this.CheckName() == true) {
        this.saving = true;
        var axios = require("axios");
        var config = {
          method: "put",
          url: "https://api.geotalent.co/api/UserProfile/262",
          data: this.userObj,
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
        };

        axios(config)
          .then((response) => {
            console.log(response.data);
            this.saving = false;
            if (response.data.status === "success") {
              this.visibility = false;
              this.LoadUser();
              this.$bvToast.toast("Setting updated.", {
                title: "Success!",
                variant: "success",
                toaster: "b-toaster-top-center",
              });
            } else {
              this.$bvToast.toast("Something went wrong.", {
                title: "Error!",
                variant: "danger",
                toaster: "b-toaster-top-center",
              });
            }
          })
          .catch(function(error) {
            console.log(error);
            this.saving = false;
          });
      } else {
        this.$bvToast.toast("Please enter the details correctly!", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}

.scrolling-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.scrolling-wrapper .moreImg {
  flex: 0 0 auto;
  position: relative;
}
</style>
